$default-app-color: #39917b;
$base-font: 'Roboto', sans-serif !default;
$code-font: 'Monaco', 'Roboto', sans-serif !default;
$base-font-size: 14px !default;
$base-color: #4e4e4e !default;

$base-error-color: #e53935 !default;
$base-info-color: #2196f3 !default;
$base-warning-color: #ffc107 !default;
$base-success-color: #39917b !default;
$base-new-color: #9c27b0 !default;

$base-error-light-color: #ffebee !default;
$base-info-light-color:  #e1f5fe !default;
$base-warning-light-color: #fffde7 !default;
$base-success-light-color: #e0f3ee !default;
$base-new-light-color: #f3e5f5 !default;

$base-background-color: #fff !default;

$layout-breakpoint-xs: 600px !default;

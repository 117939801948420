.widget {
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--base-background-color);

    &--small {
        height: 70px;
    }

    &--small-medium {
        height: 164px;
    }

    &--runtimes-bar-chart {
        height: 140px;
    }

    &--medium {
        height: 270px;
    }

    &--large {
        height: 362px;
    }

    &--xlarge {
        height: 756px;
    }

    &--no-margin {
        margin: 0;
    }

    &__loading-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--layer-ground-content-backdrop);
    }

    &__loading-indicator {
        &--pie-chart {
            margin-top: 110px;
        }
    }

    &__text-container {
        line-height: 30px;
        font-size: 22px;
        text-align: center;
        font-weight: bold;

        &--small {
            font-size: var(--base-font-size);
            line-height: 20px;
            font-weight: normal;
        }
        &--small-platform {
            font-size: 11px;
            line-height: 20px;
            font-weight: normal;
        }

        &--big {
            font-size: 28px;
        }

        &--chart-title {
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            margin-top: 6px;
        }
    }

    &__chart {
        margin-top: 6px;
        height: calc(100% - 6px);
        width: 100%;
        display: block;
    }
    &__chart-container {
        position: relative;
    }

    &__icon {
        &--middle {
            width: 45px !important;
            height: 45px !important;
            line-height: 45px !important;
            font-size: 26px !important;
            color: var(--base-text-color);
            text-align: center;
        }
        &--large {
            width: 70px !important;
            height: 70px !important;
            line-height: 70px !important;
            font-size: 40px !important;
            color: var(--base-text-color);
            text-align: center;
        }
        &--circle {
            border-radius: 50%;
            box-shadow: 1px 1px 3px 0px #bbbbbb, 0 0 4px 0 rgba(0, 0, 0, 0.12);
        }
        &--success {
            background-color: var(--base-success-color);
        }
        &--error {
            background-color: var(--base-error-color);
        }
    }

    &__status {
        &--success {
            background-color: var(--base-success-light-color);
        }
        &--error {
            background-color: var(--base-error-light-color);
        }
    }
}

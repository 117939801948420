@use '@angular-clan/core/styles/custom-styles/breakpoint-mixin' as breakpoint;

/**
 * Custom Styles that are currently missing in core and will later be moved over and merge with the core styles
 */
.vm-flex-direction-column-lt-sm {
    @include breakpoint.lt-sm() {
        flex-direction: column !important;
        flex-wrap: nowrap !important;
    }
}

.vm-flex-direction-column-lt-md {
    @include breakpoint.lt-md() {
        flex-direction: column !important;
        flex-wrap: nowrap !important;
    }
}

.vm-flex-direction-column-xs {
    @include breakpoint.xs() {
        flex-direction: column !important;
        flex-wrap: nowrap !important;
    }
}

.vm-flex-5 {
    flex: 1 1 5%;
    max-width: 5%;
}

.vm-flex-20 {
    flex: 1 1 20%;
    max-width: 20%;
}

.vm-flex-40 {
    flex: 1 1 40%;
    max-width: 40%;
}

.vm-flex-45 {
    &-sm {
        @include breakpoint.sm() {
            flex: 1 1 45%;
            max-width: 45%;
        }
    }
}

.vm-flex-48 {
    flex: 1 1 48%;
    max-width: 48%;
}

.vm-flex-75 {
    flex: 1 1 75%;
    max-width: 75%;
}

.vm-flex-10 {
    &-sm {
        @include breakpoint.sm() {
            flex: 1 1 10%;
            max-width: 10%;
        }
    }
    &-gt-md {
        @include breakpoint.gt-md() {
            flex: 1 1 10%;
            max-width: 10%;
        }
    }
}

.vm-flex-23 {
    &-gt-md {
        @include breakpoint.gt-md() {
            flex: 1 1 23%;
            max-width: 23%;
        }
    }
}

.vm-flex-45 {
    &-sm {
        @include breakpoint.sm() {
            flex: 1 1 45%;
            max-width: 45%;
        }
    }
    &-gt-md {
        @include breakpoint.gt-md() {
            flex: 1 1 45%;
            max-width: 45%;
        }
    }
}

.vm-flex-48 {
    &-sm {
        @include breakpoint.sm() {
            flex: 1 1 48%;
            max-width: 48%;
        }
    }
    &-md {
        @include breakpoint.md() {
            flex: 1 1 48%;
            max-width: 48%;
        }
    }
}

.vm-flex-70 {
    &-lt-md {
        @include breakpoint.lt-md() {
            flex: 1 1 70% !important;
            max-width: 70% !important;
        }
    }
}

.vm-layout-align-end {
    align-content: flex-end;
    align-items: flex-end;
}

.vm-layout-align-stretch-lt-sm {
    @include breakpoint.lt-sm() {
        align-items: stretch !important;
        align-content: stretch !important;
    }
}

.vm-flex-offset-4 {
    margin-left: 4%;
}

.vm-layout-justify-start {
    &-xs {
        @include breakpoint.xs() {
            justify-content: flex-start;
        }
    }
}

.vm-layout-align-center {
    &-xs {
        @include breakpoint.xs() {
            align-content: center;
            align-items: center;
        }
    }
    &-gt-xs {
        @include breakpoint.gt-xs() {
            align-content: center;
            align-items: center;
        }
    }
}

.vm-layout-justify-space-between {
    &-gt-xs {
        @include breakpoint.gt-xs() {
            justify-content: space-between;
        }
    }
}

.vm-layout-align-start {
    &-lt-sm {
        @include breakpoint.lt-sm() {
            align-items: flex-start;
        }
    }
}

.vm-flex-order-0 {
    order: 0;
}

.vm-flex-order--1-xs {
    @include breakpoint.xs() {
        order: -1;
    }
}

.vm-gap-05 {
    gap: 0.5rem;
}

.vm-hide {
    &-xs {
        @include breakpoint.xs() {
            display: none !important;
        }
    }
    &-md {
        @include breakpoint.md() {
            display: none !important;
        }
    }
    &-gt-md {
        @include breakpoint.gt-md() {
            display: none !important;
        }
    }
}

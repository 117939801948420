@use 'libs/commons/src/mixin' as mixins;
@use '@angular/material' as mat;

@import '@angular/cdk/overlay';
@import 'libs/commons/src/material-icons';

@import "@angular-clan/core/styles/core-styles";
@import "@angular-clan/core/styles/custom-styles/breakpoint-mixin";
@import "@angular-clan/core/styles/custom-styles/flex-migration-styles";
@import "@angular-clan/core/styles/custom-styles/deprecated-flex-migration-styles";
@import "@angular-clan/core/styles/custom-styles/date-range-picker-styles";

@import 'libs/commons/src/active-agent-theme';

@import "libs/commons/grid/ag-grid/themes/active-agent-theme";
@import "libs/commons/grid/ag-grid/styles";
@import "libs/commons/src/common-styles";

@import "variables";
@include mat.radio-density(-1);
@include mat.checkbox-density(-1);

:root {
    --base-font-size: #{$base-font-size};
    --base-font-color: #{$base-font-color};
    --title-font-size: 16px;
    --subtitle-font-size: 12px;
    --base-app-color: #{$default-app-color};
    --base-grey-color: #757575;
    --content-margin: 0px;
    --content-border-radius: 4px;
    --content-border-color: #70707066;
    --core-sidenav-submenu-background: #EAEAEA;
    --info-color: #42A6F5;
    --warning-color: #FFC006;
    --error-color: #E43834;
    --description-color: var(--base-app-color);
    --mat-list-hover-highlight: #E4E4E4;
    --base-link-color: #000;
    --base-link-hover-color: #000;
}

body, html {
    font-family: $base-font;
    font-size: $base-font-size;
    margin: 0;
    padding: 0;
    color: $base-color;

    // styles are coming from the old angular material, we moved them as they aren't supposed to be in the new angular material
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// We overwrite the chart icon because we currently don't need it and instead use it for share link
.ag-theme-alpine .ag-icon-chart {
    font-family: 'Material Icons';
    font-weight: normal;
}

.ag-theme-alpine .ag-icon-chart::before {
    content: 'share';
}

.ag-theme-alpine .ag-icon-loading {
    font-family: 'AdIcons';
    font-weight: 400;
}
.ag-theme-alpine .ag-icon-loading::before {
    content: 'loading-spinner';
    color: $default-app-color;
}

.grid-table-row-error {
    --ag-background-color: #{$base-error-light-color};
    --ag-range-selection-border-color: #{$base-error-color};
    --ag-alpine-active-color: #{$base-error-color};
    --ag-odd-row-background-color: #{scale-color($base-error-light-color, $lightness: -1%)};
}

.mat-mdc-menu-panel {
    &--large {
        max-width: 500px !important;
        min-width: 300px !important;
    }
}

// link
a {
    color: var(--base-link-color);
    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--base-link-hover-color);
        text-decoration: underline;
    }
}

.notification-wrapper {
    & > .mdc-snackbar__surface {
        min-width: auto !important;
    }

    &.notification-wrapper--error > .mdc-snackbar__surface {
        background-color: var(--base-error-color);
    }
    &.notification-wrapper--success > .mdc-snackbar__surface {
        background-color: var(--base-success-color);
    }
    &.notification-wrapper--info > .mdc-snackbar__surface {
        background-color: var(--base-info-color);
    }
}

.multi-tooltip-table {
    &__head,
    &__cell {
        padding: 2px 6px;

        &--numeric {
            text-align: right;
        }
    }
}

.tooltip-divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 6px 0 4px 0;
    padding: 0;
}

/**
 * currently needed for the side navigation from core library to work properly
 */
.mat-mdc-list-item {
    font-size: inherit !important;
}

/**
 * overrides z-index for the toolbar that prevents us from opening popups
 */
.sidenav__content {
    z-index: var(--layer-ground) !important;
}
.sidenav__toolbar {
    z-index: var(--layer-navigation) !important;
}

@mixin extra-small-screen {
    @media (max-width: $layout-breakpoint-xs) {
        @content;
    }
}

.reporting-templates-overlay-panel {
    top: 0 !important;
    right: 0 !important;
    left: 0;
    margin: 100px 100px 0;
    background: $base-background-color;
    border-radius: 2px;
    overflow: hidden;
    @include mixins.matDialogBoxShadow();

    @include extra-small-screen {
        margin: 10px 10px 0;
    }
}

// overwrite that can be removed once manual component in commons has the proper color (currently needed for frontend)
.content-box__header-actions libs-manual  {
    color: var(--base-grey-color) !important;
}

.content-box__header-actions mat-icon {
    color: var(--base-grey-color);
}

/**
 * To use ellipsis within the virtual scroll viewport the width by 100% is necessary
 */
.cdk-virtual-scroll-viewport.cdk-virtual-scroll-orientation-vertical.cdk-virtual-scroll-viewport--width-100 .cdk-virtual-scroll-content-wrapper {
    width: 100%;
}

.reporting-type-selection__option .mdc-list-item__primary-text {
    max-width: min-content;
    min-width: 100%;
}

.reporting-type-selection .mat-mdc-form-field-infix {
    padding: 12px 0 6px 0 !important;
}

// Material checkbox override
.mat-mdc-checkbox {
    .mdc-checkbox__ripple {
        display: none;
    }
}

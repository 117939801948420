@import "mixin";

:root {
    --base-app-color: #39917b;
    --base-app-light-color: #{lighten(#39917b, 50%)};
    --base-color: rgba(0, 0, 0, 0.87);
    --base-medium-emphasis-color: rgba(0, 0, 0, 0.6);
    --base-error-color: #e53935;
    --base-info-color: #2196f3;
    --info-light-color: #e1f5fe;
    --base-new-color: #9c27b0;
    --base-warning-color: #ffc107;
    --base-grey-color: #757575;
    --primary-border: 1px solid #e7e7e7;
    --base-grey-light-color: #{lighten(#757575, 50%)};
    --base-grey-color-rgb: 117,117,117;
    --base-special-color: #fffde7;
    --base-special-border-color: rgba(0, 0, 0, 0.1);
    --base-success-color: var(--base-app-color);
    --base-font: 'Roboto', sans-serif;
    --base-font-size: 14px;
    --subtitle-font-size: 12px;
    --base-text-color: #fff;
    --base-error-light-color: #ffebee;
    --base-error-highlight-color: #f6bebd;
    --base-info-light-color:  #e1f5fe;
    --base-warning-light-color: #fffde7;
    --base-success-light-color: #e0f3ee;
    --base-new-light-color: #f3e5f5;
    --secondary-toolbar-button-background: #F3FAF8;
    --base-background-color: #fff;
    --base-background-color-rgb: 255, 255, 255;
    --map-border-radius: 5px;
    --base-background-light-color: rgba(255, 255, 255, 0.6);
    --ssp-icon-sprite: url(../assets/ssp/ssp-iconsprite.png);
    --frequency-capping-icons: url(../assets/frequency-capping/frequency-capping-icons.png);
    --app-material-palette-100: #a9ddd0;
    --app-material-palette-50: #e0f3ee;
    --app-primary-border: 1px solid #e7e7e7;
    --map-item-z-index: 400;
    --map-margin: 10px;
    --side-bar-width: 270px;
    --layout-breakpoint-md: 1280px;
    --layout-breakpoint-xs: 600px;
    --layout-breakpoint-sm: 960px;
    --date-picker-default-width: 240px;
    --core-content-box-border-color: var(--base-app-color);
    --core-default-app-color: var(--base-app-color);
    --loading-overlay-background-color: rgba(255, 255, 255, 0.48);

    // Defines material overwrites
    --mat-select-trigger-text-size: 14px;
    --mat-option-label-text-size: 14px;

    // Defines the zIndex of the page layers
    @include availableLayers();
}

.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-size: 14px;
}

.mat-mdc-list-base {
    --mdc-list-list-item-label-text-size: 14px;
}

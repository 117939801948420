@use '@angular-clan/core/styles/custom-styles/breakpoint-mixin' as breakpoint;
@import "variables";
@import "mixin";
@import "widget";
@import "../../ui-components/src/code-mirror/styles";
@import "../../unsupported-banner-confirmation/src/styles";
@import "./global-search";
@import "./leaflet";
@import "list";
@import 'node_modules/cropperjs/dist/cropper.css';

.mat-chip {
    font-size: var(--base-font-size);
}

/**
 * Remove this in the future and replace with proper angular material typography classes
 * https://material.angular.io/guide/typography#using-typography-styles-in-your-application
 * But there is a bug in the current material version and the classes are not working properly
 */
.mat-title {
    font: 500 20px/32px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
}

a.primary-link:visited,
a.primary-link:active,
a.primary-link:hover,
a.primary-link:focus,
.primary-link {
    cursor: pointer;
    color: var(--base-app-color);
}

.grid-table-row-accent {
    --ag-background-color: var(--base-special-color);
    --ag-range-selection-border-color: var(--base-special-color);
    --ag-alpine-active-color: var(--base-special-color);
    --ag-odd-row-background-color: var(--base-special-color);
}

.grid-table-row-clickable {
    cursor: pointer;
}

/**
 * use this for custom header components
 */
.grid-table-custom-header {
    padding: 0 !important;
}

.mat-mdc-button-base,
.mat-button-wrapper {
    text-transform: uppercase;
}

libs-runtimes-config libs-date-time-selection .date-time-selection__errors,
libs-runtimes-config core-number-input mat-error.mat-error {
    display: none;
}

.tooltip {
    padding: 6px 4px;
    color: rgba(255,255,255,0.87) !important;
    white-space: pre-line;
    background-color: rgb(97, 97, 97);
    font-size: 12px;
    border-radius: 4px;
}

.atv-video-details-tooltip {
    background-color: rgba(0, 0, 0, 0.6);

    & > div {
        text-align: left;
        background-color: transparent;
    }
}

mat-form-field mat-label,
.mat-mdc-input-element {
    font-size: var(--base-font-size) !important;
}

/**
 * Use if you need markdown in mat-hint
 * myHintText = parseMarkdown('my **text**');
 * <mat-hint [innerHTML]="myHintText"></mat-hint>
 */
.mat-mdc-form-field-hint.mat-hint-markdown > * {
    display: inline;
}

/**
 * For long multiline texts with long words
 *
 * @link https://material.angular.io/components/form-field/api#MatFormField
 * <mat-form-field [subscriptSizing]="'dynamic'">
 *   <mat-hint>long multiline text</mat-hint>
 * </mat-form-field>
 */
.mat-mdc-form-field-hint.mat-hint-multiline > * {
    word-break: break-word;
}

// Set proper color for the asterisk in input fields
.mat-focused .mat-form-field-required-marker {
    color: inherit;
}

// Remove drag handler next to checkmark in columns side panel
.ag-column-panel .ag-drag-handle {
    display: none;
}

/** Global mat-dialog styles. */
.dialog-container {
    &--no-padding {
        .mdc-dialog .mdc-dialog__content  {
            padding: 0;
        }
    }
}

/* resets the background color if the global serach is opened */
libs-search-input .mat-mdc-form-field-focus-overlay {
    background-color: #fff;
}

/* allows the remove label in the history for the global search to be right aligned */
.search-input__autocomplete .mdc-list-item__primary-text {
    width: 100%;
}

/**
 * compatibility styles that are only used as long as the core-number-input is used alongside new mdc components
 */
core-number-input.mdc-compat-mode .mat-form-field-flex {
    height: 57px;
}
core-number-input.mdc-compat-mode .mat-form-field-label.mat-form-field-empty {
    margin-top: -5px;
}
core-number-input.mdc-compat-mode .mat-input-element {
    margin-top: 4px;
}

/**
 * Aligining the manual error messages similar as the implicit ones with the coreFormValidatorErrorMessages directive
 */
core-form-error-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 16px;
}

/**
 * temporary overwrite until we have proper margin in core
 */
core-grid-content-box,
core-content-box {
    margin: 1.15em;
    display: flex;
}
core-content-box.remove-margin {
    margin: 0;
}

mat-dialog-container core-content-box,
mat-dialog-content core-content-box,
core-popup core-content-box {
    margin: 0;
}

.atv-spot-video-upload__file-input-label-head strong {
    color: var(--base-app-color);
}

// end backwards compatible styles for the angular js downgrades
libs-ad-blocker-detector mat-toolbar,
commons-new-version mat-toolbar,
libs-announcement-header mat-toolbar,
core-notification-toolbar div {
    height: 56px !important;
    overflow: hidden;
}

.mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
}

.mat-mdc-option.mat-option-disabled--no-opacity .mdc-list-item__primary-text {
    opacity: 1 !important;
}

.expandable-overlay-panel {
    .mdc-list-item__primary-text {
        max-width: 340px;
    }
}

// remove the padding for autocomplete options, e.g. IDT selects
.mat-mdc-autocomplete-panel {
    padding: 0 !important;
}

core-search-toolbar {
    --mdc-icon-button-icon-color: var(--base-grey-color);
}

/**
 * Enable ellipsis for chips in idt
 */
.inventory-filter__chip .mdc-evolution-chip__text-label,
.inventory-filter__chip .mdc-evolution-chip__action,
.inventory-filter__chip .mdc-evolution-chip__cell {
    overflow: hidden !important;
}

/**
 * Enable ellipsis for idt auto complete
 */
.inventory-filter__search--virtual-scroll-viewport .mdc-label,
.inventory-filter__search--virtual-scroll-viewport .mdc-form-field {
    overflow: hidden !important;
    width: 100%;
}

.mat-mdc-form-field.mat-form-field--flex {
    width: 100%;
}

/**
 * The mat-form-field-infix has by default a width by 180px.
 * That can be a problem for smaller fields like e.g. datepicker or time selection
 */
.mat-mdc-form-field.mat-form-field--auto-width .mat-form-field-infix,
core-date-range-picker.mat-form-field--auto-width .mat-form-field .mat-form-field-infix,
libs-date-time-selection .mat-mdc-form-field-infix,
ad-select.mat-form-field--auto-width .mat-form-field .mat-form-field-infix {
    width: auto;
}

mat-form-field.mat-form-field--auto-height,
core-number-input.mat-form-field--auto-height mat-form-field {
    height: auto;
    min-height: auto;
}

.section-info:has(:empty) {
    display: none !important;
}

.mat-mdc-menu-content {
    padding: 0 !important;
}

.mat-mdc-menu-item-text {
    font-size: var(--base-font-size) !important;
}

.mat-mdc-select-panel {
    padding: 0 !important;
}

.content-box__icon {
    color: var(--base-app-color) !important;
}

mat-expansion-panel.expansion-panel-content-shadow {
    .mat-expansion-panel-content {
        border-top-width: 0;
        border-radius: 0;
        box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, .12), inset 0 -10px 10px -10px rgba(0, 0, 0, .12);
    }
}

mat-expansion-panel.expansion-panel-body--no-padding {
    .mat-expansion-panel-body {
        padding-left: 0;
        padding-right: 0;
    }
}

.grid-table-footer {
    height: 43px;
}

@import "custom-flex-styles";

.codeMirror {
    &--auto {
        & .CodeMirror {
            height: auto;
        }
    }

    &--small {
        & .CodeMirror {
            height: 100px;
        }
    }

    &--transparent {
        .CodeMirror {
            background: transparent;
        }
    }

    &--clickable {
        .CodeMirror-lines {
            cursor: pointer;
        }
    }
}

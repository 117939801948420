@use '@angular/material' as mat;

// Breakpoints
$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;

@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin min-extra-small-screen {
    @media (min-width: $layout-breakpoint-xs) {
        @content;
    }
}

@mixin min-small-screen {
    @media (min-width: $layout-breakpoint-sm) {
        @content;
    }
}

@mixin min-medium-screen {
    @media (min-width: $layout-breakpoint-md) {
        @content;
    }
}

@mixin min-large-screen {
    @media (min-width: $layout-breakpoint-lg) {
        @content;
    }
}

@mixin max-extra-small-screen {
    @media (max-width: $layout-breakpoint-xs) {
        @content;
    }
}

@mixin max-small-screen {
    @media (max-width: $layout-breakpoint-sm) {
        @content;
    }
}

@mixin max-medium-screen {
    @media (max-width: $layout-breakpoint-md) {
        @content;
    }
}

@mixin max-large-screen {
    @media (max-width: $layout-breakpoint-lg) {
        @content;
    }
}

@mixin scrollContainer() {
    overflow: auto;
    max-height: calc(50vh);
}

// Defines the order of the page layers from lowest to highest. Use this to determine the zIndex for a specific
// This will return css vars
@mixin availableLayers {
    // Defines the order of the page layers from lowest to highest. Use this to determine the zIndex for a specific
    $availableLayers:
        ground,
        ground-content-backdrop,
        ground-element-headline,
        interaction,
        backdrop,
        navigation,
        dialog,
        popup,
        dom-outline;

    @each $layer in $availableLayers {
        $z-index: index($availableLayers, $layer);

        --layer-#{$layer}: #{$z-index};
    }
}

// Uses the same shadow as for the dialog
@mixin matDialogBoxShadow {
    @include mat.elevation(24, #000);
}

@mixin unselectable() {
    // https://stackoverflow.com/a/4407335/4243635
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

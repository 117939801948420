@import "node_modules/@angular-clan/core/styles/mixins";
@import "node_modules/leaflet/dist/leaflet.css";
@import "node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";

$polygon-color: #42a78e;

.leaflet-container {
    font-family: var(--base-font);
    font-size: var(--base-font-size);
}


.leaflet-bottom.leaflet-right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;

    .leaflet-control-attribution {
        flex: 0 0 100%;
    }
}

.leaflet {
    $shadow: drop-shadow(1px 1px 2px rgba(0,0,0,.7));

    &__group {
        background: var(--base-grey-color);
        color: var(--base-text-color);
        border-radius: 14px;
        font-weight: bold;
        font-size: 11px;
        padding: 0 5px;
        margin-top: 0 !important;
        text-align: center;
        height: 24px !important;
        display: grid;
        align-items: center;
        filter: $shadow;

        &--has-selected {
            background: var(--base-app-color);
        }
    }

    &__selected-polygon {
        &:hover {
            fill-opacity: 0.7;
        }

        fill: $polygon-color;
        fill-opacity: 0.6;
        stroke: $polygon-color;
        stroke-opacity: 1;
    }

    &__deselected-polygon {
        &:hover {
            fill-opacity: 0.4;
        }

        fill: $polygon-color;
        fill-opacity: 0;
        stroke: $polygon-color;
        stroke-opacity: 0.6;
    }

    &__selected-marker-icon,
    &__deselected-marker-icon {
        &::after {
            @extend .material-icons !optional;
            content: 'location_on';
            font-size: 36px;
            filter: $shadow;
        }

        &--deleted {
            &::after {
                content: 'location_off';
            }
        }
    }

    &__selected-marker-icon::after {
        color: var(--base-app-color);
    }
    &__deselected-marker-icon::after {
        color: var(--base-grey-color);
    }

    &__editable-draw-form {
        fill: var(--base-app-color);
        stroke: var(--base-app-color);
    }

    &__empty-marker-cluster-icon {
        display: none;
    }
}

// Use the same border-radius and box-shadow as popper.js
.leaflet-popup-content-wrapper {
    @include popup();
}

.leaflet-popup-content {
    margin: 0;
    width: auto !important;
}

// We need to add a right padding to marker popup to make sure
// that it's displayed on the left side of the control buttons.
.leaflet-popup {
    padding-right: 60px;
}

/**
 * If we we are using ad-popup within a leaflet button we need to change the z-index.
 * Otherwise the buttons are overlapping over the popup
 */
.leaflet-control.leaflet-bar {
    z-index: var(--layer-ground);
    border: 1px solid rgba(var(--base-grey-color-rgb), 0.6);

    &--has-popups {
        z-index: var(--layer-dialog);
    }
}

// Prevents a space between the triangle and the popup
.leaflet-popup-tip-container {
    bottom: -19px;
}

@mixin leaflet-toolbar($map-border-radius) {
    background-color: var(--base-background-color);
    border-radius: $map-border-radius;
    border-color: rgba(var(--base-grey-color-rgb), 0.4);
    box-shadow: 0 1px 5px #000000a6;
    display: inline-flex;
    flex-direction: column;

    & > &-button:first-child {
        border-top-left-radius: var(--map-border-radius);
        border-top-right-radius: var(--map-border-radius);
    }
    & > &-button:last-child {
        border-bottom-left-radius: var(--map-border-radius);
        border-bottom-right-radius: var(--map-border-radius);
    }
}

@mixin leaflet-toolbar-core-popup-support() {
    & > core-popup:first-child > &-button {
        border-top-left-radius: var(--map-border-radius);
        border-top-right-radius: var(--map-border-radius);
    }

    & > core-popup:last-child > &-button {
        border-bottom-left-radius: var(--map-border-radius);
        border-bottom-right-radius: var(--map-border-radius);
    }
}

@mixin leaflet-toolbar-button {
    background-color: transparent;
    color: var(--base-grey-color);
    border: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    display: grid;
    align-content: center;
    justify-content: center;

    mat-icon {
        font-size: 20px;
        line-height: 24px;
    }

    &:hover,
    &--active {
        background-color: rgba(var(--base-grey-color-rgb), 0.2);
    }
}

@mixin leaflet-toolbar-button-divider {
    border-color: rgba(var(--base-grey-color-rgb), 0.4);
    margin: 0 4px;
}

@mixin leaflet-toolbar-button-divider-vertical {
    border-color: rgba(var(--base-grey-color-rgb), 0.4);
}

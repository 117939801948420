@use "mixin" as mixin;
$matFormFieldUnderlineHeight: 1px;

.global-search-panel {
    top: 0 !important;
    right: 0 !important;
    left: 80px;
    height: 554px;
    background: var(--base-background-color);
    overflow: hidden;
    @include mixin.matDialogBoxShadow();
    // use clip path here to remove the box shadow from all sides except bottom.
    clip-path: inset(0 0 -50px 0);

    @include mixin.max-medium-screen {
        left: 0;
    }

    &--sidebar-open {
        @include mixin.min-medium-screen {
            left: var(--side-bar-width);
        }
    }

    &--top-56 {
        top: 56px !important;
    }
    &--top-112 {
        top: 112px !important;
    }
    &--top-168 {
        top: 168px !important;
    }
    &--top-224 {
        top: 224px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: 0;
    }
}

@mixin backdrop-width-without-sidebar {
    width: calc(100% - var(--side-bar-width));
    left: var(--side-bar-width);
}

.global-search-backdrop {
    background: rgba(0, 0, 0, 0.32);
    width: calc(100% - 80px);
    left: 80px;


    @include mixin.max-medium-screen {
        left: 0;
        width: 100%;
    }

    &--top-56 {
        margin-top: 56px;
    }
    &--top-112 {
        margin-top: 112px;
    }
    &--top-168 {
        margin-top: 168px;
    }
    &--top-224 {
        margin-top: 224px;
    }

    &--sidebar-open {
        @include mixin.min-medium-screen {
            @include backdrop-width-without-sidebar;
        }

        @include mixin.min-large-screen {
            @include backdrop-width-without-sidebar;
        }
    }
}

.mat-form-field.mat-form-field-global-search-input {
    .mat-form-field-infix {
        border-top: 0;
    }

    .mat-form-field-flex {
        height: 64px + $matFormFieldUnderlineHeight;
        padding-top: 0;
        align-items: center;
    }
}

@use "@ag-grid-community/styles" as ag;

@include ag.grid-styles((
    // theme
    theme: alpine,
));

.ag-theme-alpine {
    --ag-borders: none;
    --ag-border-color: #E0E0E0;
    --ag-borders-row: 1px solid;
    --ag-borders-input: var(--ag-borders-row);
    --ag-borders-critical: var(--ag-borders-row);
    --ag-row-border-color: var(--ag-border-color);
    --ag-row-hover-color: var(--ag-range-selection-background-color);
    --ag-range-selection-border-color: var(--ag-alpine-active-color);
    --ag-alpine-active-color: #39917b;
    --ag-input-focus-border-color: #39917b66;
    --ag-font-family: ('Roboto', sans-serif);
    --ag-font-size: 12px;
    --ag-range-selection-background-color: rgba(0, 0, 0, 0.04);
    --ag-selected-row-background-color: rgba(0, 0, 0, .15);
    --ag-odd-row-background-color: null;
    --ag-range-selection-highlight-color: rgba(57, 145, 123, .2);
    --ag-borders-secondary: 1px solid;
    --ag-secondary-border-color: #E0E0E0;
    --ag-secondary-foreground-color: #757575;
    --ag-header-background-color: #fff;
    --ag-border-radius: 0px;
}

.ag-rtl .ag-side-bar-left,
.ag-ltr .ag-side-bar-right {
    border-left: 1px solid var(--ag-border-color);
}

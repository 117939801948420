@use '../../commons/src/mixin.scss';
/**
    * In general should not be used and instead extracted into a proper component. Just a temporary fix to keep booking running
    */

.list {
    &__description {
        margin-left: 16px;
    }

    /**
     * Used if the list is the first element in a content box, to ensure it is exactly aligned with the top of the
     * container.
     */
    &--primary {
        padding-top: 0 !important;
    }

    &--last {
        padding-bottom: 0;
    }

    &__item {
        &--disabled {
            opacity: 0.5;
        }
        &--indent-left {
            padding-left: 54px;
        }
        &--runtime-pacing-factor {
            margin-left: 108px;
        }

        &--no-padding {
            .md-no-style {
                padding: 0;
            }
        }
    }

    &__subheader-description {
        font-size: var(--base-font-size) - 2px;
        margin-top: -16px;
    }

    &__item-text {
        line-height: 20px;
        @include mixin.ellipsis();
        

        &--with-tooltip {
            z-index: 1;
        }
    }

    &__long-item-text {
        line-height: 20px;
        padding: 15px 0;
    }

    &__item-description {
        font-size: var(--base-font-size) - 2px;
        line-height: var(--base-font-size) - 2px;
        font-style: italic;
    }

    &__icon {
        /**
         * Used for icons in a list that stand as a prefix before other elements.
         */
        &--prefix {
            padding-right: 10px;
        }

        &--ssp {
            margin: 6px;
        }

        &--tooltip {
            cursor: default;
        }

        &--avatar {
            border-radius: 50%;
            width: 24px;
            margin: 16px 32px 12px auto;
        }

        &--disabled {
            opacity: 0.5
        }
    }

    &__input-container {
        &--text-companion {
            margin: 0;
            padding: 0;
        }
    }

    &__switch {
        margin: 0;
    }
}

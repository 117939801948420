.grid-table-row-disabled {
    opacity: 0.3;
    pointer-events: none;
}

// Inline editors lost their background due to styles only apply to richtext editor broke popup backgrounds
.ag-popup-editor {
    background-color: var(--ag-control-panel-background-color);
}

.grid-table-cell-editable:hover {
    cursor: pointer;
}

.grid-table-icon-column-tool-panel-class {
    display: none;
}

.ag-row-hover .grid-table-cell-editable::after {
    @extend .material-icons;
    content: 'edit';
    position: absolute;
    top: 10px;
    right: 2px;
    color: var(--base-grey-color);
    font-size: 20px;
    width: 20px;
    height: 20px;
}

.grid-table-cell-loading::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--base-app-color);
    border-right-color: var(--base-app-color);
    animation: spinner 1s linear infinite;
}
.grid-table-cell-loading::before {
    background: transparent;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}
